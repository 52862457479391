var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"disable-tap-zoom",attrs:{"id":"app"}},[_c('stats',{attrs:{"report":_vm.statsReport,"isActive":_vm.modalActive},on:{"update:isActive":function($event){_vm.modalActive=$event},"update:is-active":function($event){_vm.modalActive=$event}}}),_c('div',{staticClass:"game-container disable-tap-zoom",class:{
                    [_vm.gameState] : true, 
                    'game-over' : _vm.gameOver,
                    'modal-active' : _vm.modalActive, 
                    'enable-hard-mode' : _vm.hardMode,
                    }},[_vm._m(0),_c('guess-list',{attrs:{"guessList":_vm.guessList,"answer":_vm.answer,"activeRow":_vm.activeRow}}),_c('div',{staticClass:"status-area"},[_c('h3',{staticClass:"status status-game-in-progress"},[_vm._v(" "+_vm._s(_vm.statusMessage))]),_c('h3',{staticClass:"status status-game-lost"},[_vm._v("Sorry, the answer is "+_vm._s(_vm.answer))]),_c('h3',{staticClass:"status status-game-won"},[_vm._v("Congratulations, you got it! Please hire me!")])]),_c('word-input',{attrs:{"target":_vm.guessList[_vm.activeRow],"validator":_vm.validateWord},on:{"update:target":function($event){return _vm.$set(_vm.guessList, _vm.activeRow, $event)},"guess-validated":_vm.guessValidated,"message":_vm.setStatusMsg}}),_c('div',{staticClass:"footer"},[_c('label',{staticClass:"hard-checkbox small-text"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hardMode),expression:"hardMode"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.hardMode)?_vm._i(_vm.hardMode,null)>-1:(_vm.hardMode)},on:{"change":function($event){var $$a=_vm.hardMode,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.hardMode=$$a.concat([$$v]))}else{$$i>-1&&(_vm.hardMode=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.hardMode=$$c}}}}),_c('b',[_vm._v("Hard Mode:")]),_vm._v(" when checked, grey letters cannot be reused ")]),_c('br'),_c('br'),_c('span',{staticClass:"correct"},[_vm._v("Green")]),_vm._v(": correct; "),_c('span',{staticClass:"elsewhere"},[_vm._v("Yellow")]),_vm._v(": wrong position; "),_c('span',{staticClass:"miss"},[_vm._v("Grey")]),_vm._v(": not in word "),_c('br'),_c('span',{staticClass:"small-text version-info"},[_vm._v(" app_ver: "+_vm._s(_vm.appVersion)+" "),(_vm.apiVersion !== '')?_c('span',[_vm._v(", api_ver: "+_vm._s(_vm.apiVersion)+" ")]):_vm._e()])])],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('h3',{staticClass:"title"},[_vm._v("Bill's NYTimes "),_c('a',{attrs:{"href":"https://www.nytimes.com/games/wordle/index.html","target":"_blank"}},[_vm._v("Wordle")]),_vm._v("™ Clone")])
}]

export { render, staticRenderFns }