
"use strict";
// @ts-check

import Vue,{ PropType}   from 'vue'

export default Vue.extend({
     name: 'guess-letter',

     data() {
               return { 
          };
     },

     props: {
          'character': {
               type : String as PropType<string>,
               required : true,
          },
     },
});
  
