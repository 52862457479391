
"use strict";
// @ts-check

import Vue, { PropType }  from 'vue'
import GuessLetter from './GuessLetter.vue';
import { calcLetterColor } from '@/utils/Game';

export default Vue.extend({
     name: 'guess-list',

     data() 
     {
          return {};
     },

     components: { GuessLetter },

     props: 
     {
          'guessList': {
               type: Array as PropType<string[]>,
               required : true,
          },
          'activeRow': {
               type: Number as PropType<number>,
               required : true,
          },
          'answer': {
               type: String as PropType<string>,
               required : true,
          },
     },     

     computed: {
          currentGuess() : string {
               return this.guessList[ this.activeRow ];
          },

          cursorCol() : number {
               return this.currentGuess.length;
          },
     },

     methods: {
          getBoxColor( row: number, col: number ) : string
          {
               return calcLetterColor( this.guessList[row], this.answer, col).color;
          },

          getBoxLetter( row: number, col: number ) : string
          {
               return calcLetterColor( this.guessList[row], this.answer, col).letter;
          },
     },
});
