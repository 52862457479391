
"use strict";
// @ts-check

import Vue, {PropType} from 'vue'
import StatBar from './StatBar.vue'
import CumulativeStats from '@/CumulativeStats'
import { assert } from '@/utils/Misc';
import { GameStates, StatsReportGameResult, } from '@/types';

export default Vue.extend({
     name: 'statbox',

     data  () {
          return { 
               cumStats : new CumulativeStats(),
               showDialog : false,
          };
     },

     components: {
          statbar: StatBar,
     },

     props: {
          report : {
               type: Object as PropType<Record<string,unknown>>,
          },
          isActive : {
               type: Boolean as PropType<boolean>,
               required : true,
          }
     },

     watch: {
          report( newVal : StatsReportGameResult )
          {
               if( !newVal || typeof newVal !== 'object' ) 
                    return;

               let newStats;
               switch( newVal.finalState )
               {
                    case GameStates.WON:
                         // if( newVal.numGuesses === undefined )
                         //      throw new Error( 'ASSERT: newVal.numGuesses is undefined');
                         assert( !(newVal.numGuesses===undefined), 'newVal.numGuesses is not undefined');
                         newStats = CumulativeStats.recordWin( newVal.numGuesses as number);
                         break;
                    case GameStates.LOST:
                         newStats = CumulativeStats.recordLoss();
                         break;
                    default:
                         throw new Error( 'impossible  game state encountered' );
               }
               this.cumStats = Object.assign( {}, this.cumStats, newStats );
               this.$emit('update:isActive', true );

               this.showDialog = true;
          },
     },

     methods: {
          histogramPct( NTries:number  ) : number {
               return Math.floor(  ( this.cumStats.histogramBins[ NTries - 1 ] / this.cumStats.gamesWon ) * 100 + 0.5) ;
          },

          closeDialog() : void
          {
               this.showDialog = false;
               this.$emit('update:isActive', false );
          },

          handleKey(e : KeyboardEvent) 
          {
               if ( this.showDialog && (e.key === 'Enter' || e.key==='Escape')) 
                    this.closeDialog();
          }
     },

     computed: {
          winPct: function() : number {
               return Math.floor( ( this.cumStats.gamesWon / this.cumStats.gamesPlayed ) * 100 + 0.5 );
          },

          lossPct: function() : number {
               return Math.floor( ( ( this.cumStats.gamesPlayed - this.cumStats.gamesWon ) / this.cumStats.gamesPlayed ) * 100 + 0.5 );
          }
     },

     mounted() {
          this.handleKey = this.handleKey.bind(this);   
          window.addEventListener('keydown', this.handleKey);
     },
});
