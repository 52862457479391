
"use strict";
// @ts-check

import Vue  from 'vue'
import GuessList from '@/components/GuessList.vue'
import WordInput from '@/components/WordInput.vue'
import Stats from '@/components/Stats.vue'
import { mapState, mapActions,  } from 'pinia'
import { useStateStore, } from '@/Store';
import { EventBus, } from '@/EventBus';
import { wordlessApiService, CheckWordAsyncResponseType} from '@/WordlessAPI'
import { CustomEventNames, GameStates, StatsReportGameResult,
     ResetGameEventArgs, } from '@/types';


export default Vue.extend({
     name: 'App',

     data() { 
          return {
               statsReport: {} as StatsReportGameResult,
               modalActive: false,
               hardMode: false,
          };
     },

     components: { 
          'guess-list': GuessList,
          'word-input': WordInput,
          'stats': Stats,
     },

     computed: {
          ...mapState( useStateStore, [ 'answer', 'statusMessage', 'gameState', 
               'guessList', 'activeRow', 'gameOver', 'apiVersion', 'appVersion', ] ),
     },

     methods: {
          ...mapActions( useStateStore,[ 'setGameState', 'setStatusMsg', 
                'advanceNextRow', 'sendActiveGuessColorsToKB',
                 'setAnswer', ] ),

          resetEventHandler( eventArgs  : ResetGameEventArgs) : void
          {
               this.setGameState( GameStates.RUNNING );
               this.setAnswer( eventArgs.answer);
          },

          guessValidated( guess: string ) : void
          {
               this.sendActiveGuessColorsToKB();
               this.advanceNextRow();
               if( guess === this.answer )
               {
                    this.setGameState( GameStates.WON );
                    this.statsReport = { finalState: GameStates.WON, numGuesses: this.activeRow };
               }
               else if (this.activeRow >= 6 )
               {
                    this.setGameState( GameStates.LOST );
                    this.statsReport = { finalState: GameStates.LOST };
               }
               else
                    this.displayMatchingWordCount( this.answer, this.guessList.slice( 0, this.activeRow ) );
          },

          async displayMatchingWordCount( answer: string, guesses: string[] ): Promise<void>
          {
               let apiResp = await wordlessApiService.getMatchCountAsync( answer, guesses );
               if( !apiResp.success )
               {
                    this.setStatusMsg( `Failed to calc remaining: ${apiResp.message}` );
               }
               else
               {
                    this.setStatusMsg( `${apiResp.count} remaining word(s) match the clues above.` );
               }
          },

          async validateWord( word :string ): Promise<CheckWordAsyncResponseType>
          {
               return await wordlessApiService.checkWordAsync( word );
          }
     },

     mounted() {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          EventBus.startListen( this.resetEventHandler as any, CustomEventNames.RESET_COMPONENTS );
     },
     
     beforeDestroy() 
     {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          EventBus.stopListen( this.resetEventHandler as any, CustomEventNames.RESET_COMPONENTS );
     },
});
