
"use strict";
// @ts-check

import Vue, { PropType }  from 'vue'

export default Vue.extend({
     name: 'statsbar',

     data  () {
          return { 
          };
     },

     props: {
          percent : {
               type : Number as PropType<number>,
               required : true,
          },
          caption : {
               type : String as PropType<string>,
               required : false,
               default : '',
          },
     },

     computed: {
          barCount() : number {
               return Math.floor( ( this.percent + 5) / 10 );
          }
     },
});

