
"use strict";
// @ts-check

import Vue, {PropType, }  from 'vue'
import { mapState,  } from 'pinia'
import { useStateStore, } from '@/Store';
import {  MatchCodes, } from '@/types';

export default Vue.extend({
     name: 'key',

     data  () {
          return { 
               keyDown : false,
          };
     },

     props: {
          label : {
               type : String as PropType<string>,
               default : "",
          },

          special_key : {
               type : Boolean as PropType<boolean>,
               default : false,
          },

          // character/mnemonic emitted with key click event
          char : {
               type : String as PropType<string>, 
               required : true,
          },
          
          refMap : {
               type: Object as PropType<Record<string, unknown>>,
               required : true,
          },
     },

     computed: 
     {
          ...mapState( useStateStore, [ 'keyColorMap', ] ),
          getCSSClasses() : Record<string, boolean>
          {

               let colorClass = this.keyColorMap[ this.char ] ?? MatchCodes.DEFAULT;

               let classes = {
                    special_key : this.special_key,
                    [ 'key-' + this.char.toLowerCase() ] : true,
                    [ this.special_key ? 'key-nonalpha' : 'key-alpha' ] : true,
                    [ colorClass ] : true,
                    'key-down' : this.keyDown,
               };

               return classes;
          },
      },

     methods: 
     {
          clickHandler( ) : void
          {
               /**                
                * check if disabled in css so keypress-
                * calls are ignored (native click will be 
                * disabled by css setting natively.)
                **/
               if( !this.isCssPointerEventDisabled() )
               {
                    this.keyDown = true;
                    this.$emit( 'click', { character: this.char } );
                    setTimeout( ()=>( this.keyDown = false ), 100 );
               }
          },

          isCssPointerEventDisabled() : boolean 
          {
               let styles = window.getComputedStyle( this.$el );
               let val = styles.getPropertyValue( 'pointer-events' );
               return ( val === 'none' ) ;
          },
     },

     mounted() {
          this.refMap[ this.char ] = this;
     },

});
