
/* eslint-disable no-unused-vars */

import Vue  from 'vue'
import Key from './Key.vue'
import { KeyPressEventArgs, }  from '@/types'

export default Vue.extend({
     name: 'keyboard',

     data() 
     {
          return {
                refMap : {} as Record<string, unknown>,
          };
     },

     components: {
          Key
     },

     methods: {
          keyHandler( args : KeyPressEventArgs ) : void 
          {
               this.$emit( 'key-press', args );
          },

          handleRealKey( e : KeyboardEvent ) : void
          {
               let ucKey = ( e.key || '' ).toUpperCase();
               console.log(`key ${ucKey}`);
               if( ucKey in this.refMap )
               {
                    let keyRef = this.refMap[ ucKey ];
                    {
                         ( keyRef as { clickHandler: ()=>void } ).clickHandler();
                    }
               }
          },

     },

     mounted() {
          this.handleRealKey = this.handleRealKey.bind(this);   
          window.addEventListener( 'keydown', this.handleRealKey );
     },

     beforeDestroy() 
     {
          window.removeEventListener( 'keydown', this.handleRealKey );
     },

});
